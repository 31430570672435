<template>
  <div v-if="complaint" class="w-full px-0 py-0">
    <div class="grid grid-cols-2 gap-4" v-if="complaint.sexo">
      <BaseInput
        type="text"
        label="Sexo"
        v-model="complaint.sexo"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="LGTBIQ+"
        v-model="complaint.lgbtiq"
        :disabled="true"
      />
    </div>
    <BaseRadio
      ref="especialCondicion"
      v-if="complaint.specialCondition !== 0"
      name="especialCondicion"
      label="Condición especial"
      :radioOptions="optionsYN"
      v-model="complaint.specialCondition"
      :disabled="true"
    />
    <BaseInput
      type="text"
      label="Cuál"
      v-if="complaint.specialCondition == 1"
      v-model="complaint.condicion_especial"
      :disabled="true"
    />
    <div class="w-full mb-5">
      <h2 class="my-5 text-2xl text-left text-blue">
        <b>Detalle de la queja</b>
      </h2>
      <BaseRadio
        ref="abroad"
        name="abroad"
        label="La inconformidad que motiva su queja ocurrió en el exterior"
        :radioOptions="optionsYN"
        v-model="abroad"
        :disabled="true"
      />
      <div v-if="complaint.abroad == 2" class="grid grid-cols-2 gap-4">
        <BaseInput
          type="text"
          label="Departamento"
          v-model="complaint.departamento_cod"
          :disabled="true"
        />
        <BaseInput
          type="text"
          label="Municipio"
          v-model="complaint.municipio_cod"
          :disabled="true"
        />
      </div>
      <BaseInput
        v-else
        type="text"
        label="País"
        v-model="complaint.codigo_pais"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Entidad con la que se tiene la inconformidad"
        v-model="complaint.entidad_cod"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="La inconformidad está relacionada con alguno de los siguientes productos"
        v-model="complaint.producto_cod"
        :disabled="true"
      />
      <BaseInput
        type="text"
        label="Motivo de su inconformidad"
        v-model="complaint.macro_motivo_cod"
        :disabled="true"
      />

      <!-- <pre>{{ complaint }}</pre> -->
      <div class="w-11/12 grid grid-cols-1 gap-2" v-if="this.showInpus">
        <!-- Tipo de fraude  -->
        <BaseSelect
          v-if="complaint.tipo_fraude && complaint.tipo_fraude != null"
          :disabled="true"
          ref="tipo_fraude"
          id="tipo_fraude"
          label="Tipo de fraude"
          :selectOptions="formOptions.optionsFraudTypes"
          v-model="complaint.tipo_fraude"
          required="true"
        />
        <!-- Modalidad fraude  -->
        <BaseSelect
          v-if="
            complaint.modalidad_fraude && complaint.modalidad_fraude != null
          "
          :disabled="true"
          ref="modalidad_fraude"
          id="modalidad_fraude"
          label="Modalidad fraude"
          required="true"
          :selectOptions="formOptions.optionsFraudModes"
          v-model="complaint.modalidad_fraude"
        />
        <!-- Monto reclamado  -->
        <BaseInput
          v-if="complaint.monto_reclamado && complaint.monto_reclamado != null"
          :disabled="true"
          ref="monto_reclamado"
          type="text"
          label="Monto reclamado"
          v-model="complaint.monto_reclamado"
        />
        <!-- Monto reconocido  -->
        <BaseInput
          v-if="
            complaint.monto_reconocido && complaint.monto_reconocido != null
          "
          :disabled="true"
          ref="monto_reconocido"
          type="text"
          label="Monto reconocido"
          v-model="complaint.monto_reconocido"
        />
        <!-- # Cierre nuevos campos  -->
      </div>

      <BaseCheckbox
        v-if="complaint.check_habeas_data"
        id="habeas_data"
        :name="'habeas_data'"
        :showAll="false"
        :disabled="true"
        class="my-8"
        label="Si buscas que la SFC revise tu caso para la corrección, actualización, eliminación o retiro de tus datos financieros en los operadores o centrales de información, marca la casilla."
        :options="[{ id: 1, name: 'Acepto', checked: true }]"
      />
      <BaseTextarea
        label="Detalle del producto"
        placeholder="Descripción"
        v-model="complaint.producto_nombre"
        :disabled="true"
        maxlength="100"
      />
      <BaseInput
        type="text"
        label="Canal"
        v-model="complaint.canal_cod"
        :disabled="true"
      />
      <BaseTextarea
        ref="describe"
        id="describe"
        label="Describa los hechos brevemente:"
        placeholder="Mensaje"
        v-model="complaint.texto_queja"
        required="true"
        :disabled="true"
        maxlength="4500"
      />
      <BaseRadio
        ref="attorney"
        name="apoderado"
        label="¿Actúa en nombre propio o por apoderado?"
        :radioOptions="optionsApoderado"
        v-model="complaint.es_apoderado"
        required="true"
        :disabled="true"
      />

      <div v-if="complaint.es_apoderado == 2" class="w-full">
        <BaseInput
          ref="attorneyName"
          type="text"
          id="apoderadoName"
          label="Nombre completo del apoderado:"
          placeholder="Ej: Lina Maria De Castro Jaramillo"
          v-model="complaint.apoderado_nombre"
          required="true"
          :disabled="true"
        />
        <BaseInput
          ref="attorneyEmail"
          type="text"
          id="apoderadoEmail"
          label="Correo electrónico del apoderado:"
          placeholder="Ej: apoderado@ejemplo.com"
          v-model="complaint.apoderado_correo"
          required="true"
          :disabled="true"
        />
      </div>
      <div
        class="flex flex-col gap-2"
        v-if="complaint && complaint.archivos_apoderado.length > 0"
      >
        <label
          class="text-xs md:text-xs text-gray-600 text-opacity-80 mt-5 text-left"
          ><b>Anexos de autorización del poder</b></label
        >
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in complaint.archivos_apoderado"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  // "accordionOpen"
  props: ["complaint"],
  data() {
    return {
      feedback: "",
      abroad: 0,
      specialCondition: 0,
      optionsIdentificationType: [],
      showInpus: false,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
    };
  },
  async created() {
    this.abroad = this.complaint.abroad;
    this.specialCondition = this.complaint.specialCondition;

    let optionsReasonsCompany = await this.$store.dispatch(
      "form/loadReasonsOpt",
      { company: this.complaint.company }
    );

    const response = optionsReasonsCompany.some(({ name, is_fraud }) => {
      if (
        name.toUpperCase() == this.complaint.macro_motivo_cod.toUpperCase() &&
        is_fraud
      ) {
        return true;
      } else {
        return false;
      }
    });

    response ? (this.showInpus = true) : (this.showInpus = false);
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
};
</script>